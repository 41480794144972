/* font size*/
/* font weight*/
/* font size*/
/* font weight*/
/* font size*/
/* font weight*/
/* font size*/
/* font weight*/
.navbar .brand {
  padding: 0px 10px;
  height: 100%;
  cursor: pointer;
  position: relative; }
  .navbar .brand-content {
    position: relative;
    color: #ffbf00;
    height: 28px;
    font-weight: bold;
    font-size: 22px;
    display: flex;
    align-items: center; }
    .navbar .brand-content img {
      height: 100%; }

.navbar-vertical .brand {
  padding: 0;
  height: auto; }
  .navbar-vertical .brand-content {
    height: auto; }
  .navbar-vertical .brand svg {
    width: 45px;
    height: 45px; }

.navbar-left {
  display: flex;
  align-items: center; }
  .navbar-left .nav-item {
    margin-left: 10px; }

.navbar-vertical .navbar-left {
  flex-direction: column;
  width: 100%; }
  .navbar-vertical .navbar-left .nav-item {
    margin: 0;
    margin-top: 5px; }

.navbar-right {
  display: flex;
  align-items: center; }

.navbar-vertical .navbar-right {
  flex-direction: column;
  width: 100%; }

/* font size*/
/* font weight*/
/* font size*/
/* font weight*/
.navbar .nav-item {
  display: flex;
  font-size: 14px;
  padding: 5px 10px;
  align-items: center;
  border-radius: 3px;
  border: 1px solid transparent;
  transition: all 100ms;
  user-select: none;
  cursor: pointer; }
  .navbar .nav-item svg {
    height: 16px; }
  .navbar .nav-item.active {
    background-color: #5a6675;
    box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.2); }
  .navbar .nav-item:hover {
    border-color: #5a6675; }
  .navbar .nav-item:active, .navbar .nav-item.active:active {
    border-color: transparent;
    background-color: #4f5967;
    box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.2); }

.navbar a.nav-item {
  text-decoration: none;
  color: #fff; }

.navbar-vertical .nav-item {
  margin: 0;
  width: 100%;
  padding: 5px 0px;
  font-size: 10px;
  justify-content: center;
  flex-direction: column; }
  .navbar-vertical .nav-item svg {
    margin: 2.5px; }

/* font size*/
/* font weight*/
.navbar-separator {
  height: 30px;
  width: 2px;
  background: #5a6675;
  margin: 0 10px; }

.navbar-vertical .navbar-separator {
  width: 100%;
  height: 2px;
  margin: 10px 0; }

.navbar {
  background-color: #3d4755;
  box-sizing: border-box;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
  height: 50px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff; }
  .navbar svg {
    fill: #fff; }
  .navbar-vertical {
    width: 70px;
    height: 100%;
    padding: 10px;
    padding-bottom: 10px;
    flex-direction: column; }
