/* font size*/
/* font weight*/
/* font size*/
/* font weight*/
@keyframes stripes-animation {
  from {
    background-position: 0 0; }
  to {
    background-position: 80px 40px; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fh-button {
  white-space: nowrap;
  position: relative;
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 10px;
  color: white;
  border: 2px solid transparent;
  background-color: transparent;
  border-radius: 3px;
  text-transform: uppercase;
  transition: box-shadow 50ms;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  text-decoration: none; }
  .fh-button:focus {
    box-shadow: 0 0 0 1px #29313e, 0 0 0 4px #1b2028; }
  .fh-button[disabled] {
    pointer-events: none;
    opacity: 0.6; }
  .fh-button.blank svg {
    margin: 0; }
  .fh-button svg {
    margin-right: 5px;
    fill: white; }
  .fh-button.loading {
    animation: stripes-animation 2.5s linear infinite;
    background-size: 40px 40px;
    background-image: linear-gradient(-45deg, #47556c 25%, transparent 25%, transparent 50%, #47556c 50%, #47556c 75%, transparent 75%, transparent); }
  .fh-button:active {
    background: #1b2028; }
  .fh-button.primary {
    color: white;
    background: #357fbd;
    border: 0; }
    .fh-button.primary svg {
      fill: white; }
    .fh-button.primary:hover {
      background: #428cca; }
    .fh-button.primary:active {
      background: #2f72a9; }
    .fh-button.primary.loading {
      animation: stripes-animation 2.5s linear infinite;
      background-size: 40px 40px;
      background-image: linear-gradient(-45deg, #69a4d5 25%, transparent 25%, transparent 50%, #69a4d5 50%, #69a4d5 75%, transparent 75%, transparent); }
    .fh-button.primary:focus {
      box-shadow: 0 0 0 1px #29313e, 0 0 0 4px #357fbd; }
  .fh-button.primary-yellow {
    color: #29313e;
    background: #ffbf00;
    border: 0; }
    .fh-button.primary-yellow svg {
      fill: #29313e; }
    .fh-button.primary-yellow:hover {
      background: #ffc51a; }
    .fh-button.primary-yellow:active {
      background: #e6ac00; }
    .fh-button.primary-yellow.loading {
      animation: stripes-animation 2.5s linear infinite;
      background-size: 40px 40px;
      background-image: linear-gradient(-45deg, #ffd24d 25%, transparent 25%, transparent 50%, #ffd24d 50%, #ffd24d 75%, transparent 75%, transparent); }
    .fh-button.primary-yellow:focus {
      box-shadow: 0 0 0 1px #29313e, 0 0 0 4px #ffbf00; }
  .fh-button.secondary {
    color: #357fbd;
    border: 2px solid #357fbd; }
    .fh-button.secondary svg {
      fill: #357fbd; }
    .fh-button.secondary:hover {
      color: #428cca;
      border-color: #428cca; }
    .fh-button.secondary:active {
      color: #2f72a9;
      border-color: #2f72a9; }
    .fh-button.secondary.loading {
      animation: stripes-animation 2.5s linear infinite;
      background-size: 40px 40px;
      background-image: linear-gradient(-45deg, #47556c 25%, transparent 25%, transparent 50%, #47556c 50%, #47556c 75%, transparent 75%, transparent); }
    .fh-button.secondary:focus {
      box-shadow: 0 0 0 1px #29313e, 0 0 0 4px #357fbd; }
  .fh-button.secondary-yellow {
    color: #ffbf00;
    border: 2px solid #ffbf00; }
    .fh-button.secondary-yellow svg {
      fill: #ffbf00; }
    .fh-button.secondary-yellow:hover {
      color: #ffc51a;
      border-color: #ffc51a; }
    .fh-button.secondary-yellow:active {
      color: #e6ac00;
      border-color: #e6ac00; }
    .fh-button.secondary-yellow.loading {
      animation: stripes-animation 2.5s linear infinite;
      background-size: 40px 40px;
      background-image: linear-gradient(-45deg, #47556c 25%, transparent 25%, transparent 50%, #47556c 50%, #47556c 75%, transparent 75%, transparent); }
  .fh-button.destructive {
    border: 2px solid #ff704d;
    background: #ff704d; }
    .fh-button.destructive svg {
      fill: white; }
    .fh-button.destructive:hover {
      background: #ff8467; }
    .fh-button.destructive:active {
      background: #ff5c34; }
    .fh-button.destructive:focus {
      box-shadow: 0 0 0 1px #29313e, 0 0 0 4px #ff704d; }
    .fh-button.destructive.loading {
      animation: stripes-animation 2.5s linear infinite;
      background-size: 40px 40px;
      background-image: linear-gradient(-45deg, #ffad9a 25%, transparent 25%, transparent 50%, #ffad9a 50%, #ffad9a 75%, transparent 75%, transparent); }
  .fh-button.secondary-destructive {
    color: #ff704d;
    border: 2px solid #ff704d; }
    .fh-button.secondary-destructive svg {
      fill: #ff704d; }
    .fh-button.secondary-destructive:hover {
      color: #ff8467;
      border-color: #ff8467; }
    .fh-button.secondary-destructive:active {
      color: #ff5c34;
      border-color: #ff5c34; }
    .fh-button.secondary-destructive.loading {
      animation: stripes-animation 2.5s linear infinite;
      background-size: 40px 40px;
      background-image: linear-gradient(-45deg, #47556c 25%, transparent 25%, transparent 50%, #47556c 50%, #47556c 75%, transparent 75%, transparent); }
